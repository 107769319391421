import { render, staticRenderFns } from "./KpiPill.vue?vue&type=template&id=2addabbf"
import script from "./KpiPill.vue?vue&type=script&lang=js"
export * from "./KpiPill.vue?vue&type=script&lang=js"
import style0 from "./KpiPill.vue?vue&type=style&index=0&id=2addabbf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports